<template>
  <div>
    <PageHeader>
      <template slot="title">戻る</template>
      <template slot="content">カテゴリー</template>
    </PageHeader>
    <template>カテゴリー一覧画面です。</template>
    <template
      >この画面でカテゴリーの確認できますし、編集ボタンから編集画面に遷移もできます。</template
    >
    <el-divider></el-divider>
    <el-row :gutter="10">
      <el-col :xs="24" :sm="24" :md="20" :lg="16" :xl="12">
        <el-row :gutter="40">
          <el-col :span="24">
            <el-input v-model="category_name">
              <template slot="prepend">カテゴリー</template>
            </el-input>
          </el-col>
        </el-row>
        <br />
        <el-row :gutter="40">
          <el-col :span="24">
            <el-input v-model="sorting">
              <template slot="prepend">区分</template>
            </el-input>
          </el-col>
        </el-row>
      </el-col>
    </el-row>
    <br />
    <el-row :gutter="10">
      <el-col :span="4">
        <el-button
          class="addBtn"
          type="info"
          @click="createBtn()"
          icon="el-icon-document-add"
          plain
          >新 規
        </el-button>
      </el-col>
    </el-row>
    <!--    <el-row :gutter="20">-->
    <!--      <el-col :span="24">-->
    <!--        <el-form :model="ruleForm" ref="ruleForm" class="demo-ruleForm">-->
    <!--          <el-row type="flex" :gutter="40" justify="left">-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="category_name">-->
    <!--                <el-input v-model="ruleForm.category_name" placeholder="カテゴリー名">-->
    <!--                  <template slot="prepend">カテゴリー名</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            <el-col :span="8">-->
    <!--              <el-form-item prop="Parent_id">-->
    <!--                <el-input v-model="ruleForm.sorting" placeholder="区分名">-->
    <!--                  <template slot="prepend">区分名</template>-->
    <!--                </el-input>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--            &lt;!&ndash;            <el-col :span="2">&ndash;&gt;-->
    <!--            &lt;!&ndash;              <el-form-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-affix :offset-top="10">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-search" style="width: 90px">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    検索&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </el-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-affix>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-affix :offset-top="50">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <el-button type="warning" @click="resetForm('ruleForm')" style="width: 90px">クリア</el-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-affix>&ndash;&gt;-->
    <!--            &lt;!&ndash;                <a-affix :offset-top="90">&ndash;&gt;-->
    <!--            &lt;!&ndash;                  <el-button type="success" @click="createBtn" icon="el-icon-document-add" style="width: 90px">&ndash;&gt;-->
    <!--            &lt;!&ndash;                    新規&ndash;&gt;-->
    <!--            &lt;!&ndash;                  </el-button>&ndash;&gt;-->
    <!--            &lt;!&ndash;                </a-affix>&ndash;&gt;-->
    <!--            &lt;!&ndash;              </el-form-item>&ndash;&gt;-->
    <!--            &lt;!&ndash;            </el-col>&ndash;&gt;-->
    <!--          </el-row>-->
    <!--          <el-row type="flex" :gutter="20" justify="start">-->
    <!--            <el-col :span="11">-->
    <!--              <el-form-item>-->
    <!--                <el-button-group>-->
    <!--                  <el-button type="primary" @click="submitForm('ruleForm')" icon="el-icon-search">検索</el-button>-->
    <!--                  <el-button type="warning" @click="resetForm('ruleForm')">クリア</el-button>-->
    <!--                  <el-button type="success" @click="createBtn">新規</el-button>-->
    <!--                </el-button-group>-->
    <!--              </el-form-item>-->
    <!--            </el-col>-->
    <!--          </el-row>-->
    <!--        </el-form>-->
    <!--      </el-col>-->
    <!--    </el-row>-->
    <el-row :gutter="20">
      <el-col :span="24">
        <el-divider></el-divider>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table
          :data="
            tableData
              .filter(
                (data) =>
                  !search ||
                  data.category_name
                    .toLowerCase()
                    .includes(search.toLowerCase())
              )
              .filter(
                (data) =>
                  !category_name ||
                  data.category_name
                    .toLowerCase()
                    .includes(category_name.toLowerCase())
              )
              .filter(
                (data) =>
                  !sorting ||
                  data.sorting.toLowerCase().includes(sorting.toLowerCase())
              )
          "
          v-loading="loading"
          style="width: 100%"
        >
          <template slot="empty">カテゴリーがありません</template>
          <el-table-column label="親カテゴリー" prop="parent_id">
          </el-table-column>
          <el-table-column label="カテゴリー" prop="category_name">
            <template slot-scope="scope">
              <a @click="handleEdit(scope.$index, scope.row)" class="rowTxt">{{
                scope.row.category_name
              }}</a>
            </template>
          </el-table-column>
          <el-table-column label="区分" prop="sorting"> </el-table-column>
          <el-table-column align="right">
            <!--eslint-disable-next-line-->
            <template slot="header" slot-scope="scope">
              <el-input
                v-model="search"
                size="mini"
                placeholder="カテゴリー名で絞ります"
              />
            </template>
            <!--eslint-disable-next-line-->
            <template slot-scope="scope">
              <el-button
                size="mini"
                type="danger"
                @click="handleDelete(scope.$index, scope.row)"
                plain
                >削除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <div>
      <el-dialog
        title="カテゴリー登録・更新"
        :visible.sync="dialogFormVisible"
        top="5%"
        v-if="dialogFormVisible"
        :close-on-click-modal="false"
        destroy-on-close
      >
        <CategoryForm
          :categoryId="id"
          @cancel="closeDialog"
          @submit="reloadList"
        ></CategoryForm>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PageHeader from "components/common/menu/PageHeader";
import CategoryForm from "@/views/master/CategoryForm";
import {
  getCategoryDataAll,
  deleteCategory,
  searchCategoryData,
} from "api/category";
import { Message } from "element-ui";

export default {
  name: "CategoryList",
  components: {
    PageHeader,
    CategoryForm,
  },
  data() {
    return {
      ruleForm: {
        category_name: "",
        sorting: "",
      },
      category_name: "",
      sorting: "",
      id: "",
      dialogFormVisible: false,
      loading: false,
      tableData: [],
      search: "",
    };
  },
  created() {
    this.getCategoryDataAll();
  },
  methods: {
    getCategoryDataAll() {
      this.loading = true;
      getCategoryDataAll()
        .then((res) => {
          if (res.data.status === 1) {
            this.tableData = res.data.data;
            //将父商品分类ID转换为商品分类名
            // for (let idItem of this.tableData) {
            //   for (let parentIdItem of this.tableData) {
            //     if (idItem.parent_id == parentIdItem.id) {
            //       idItem.parent_id = parentIdItem.category_name
            //     }
            //   }
            // }
            this.loading = false;
          } else {
            this.tableData = [];
            this.loading = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    searchCategoryData() {
      let values = {};
      values["category_name"] = this.ruleForm.category_name;
      values["sorting"] = this.ruleForm.sorting;
      searchCategoryData(values)
        .then((res) => {
          console.log(res);
          this.tableData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.searchCategoryData();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleEdit(index, row) {
      this.id = row.id;
      this.dialogFormVisible = true;
    },
    handleDelete(index, row) {
      // this.loading = true
      let values = {};
      values["id"] = row.id;
      deleteCategory(values)
        .then((res) => {
          if (res.status === 200) {
            this.getCategoryDataAll();
            Message({
              showClose: true,
              message: res.data.message,
              type: "success",
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    reloadList() {
      this.dialogFormVisible = false;
      this.getCategoryDataAll();
    },
    createBtn() {
      this.id = "";
      this.dialogFormVisible = true;
      console.log(this.dialogFormVisible);
    },
  },
};
</script>

<style scoped>
</style>
